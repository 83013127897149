<template>
  <div>

    <modal className="w-full md:w-2/5 lg:w-5/12 xl:w-3/10 pt-12 px-12" ref="modal">

      <h3 class="text-xl font-bold mb-10">Expense Details</h3>

      <form @submit.prevent="submit">

        <template v-if="getFormError(form)">
          <div class="alert alert-red-soft mb-10">
            <span class="alert-icon">!</span>
            <span class="">{{ getFormError(form) }}</span>
          </div>
        </template>
        
        <form-group
          type="textarea"
          left-icon="receipt-outline"
          name="description"
          :form="form"
          v-model="form.data.description.value"
        >
          Description
        </form-group>

        <form-group
          type="select"
          :options="subCategories"
          left-icon="people-circle-outline"
          name="expense_category"
          :form="form"
          v-model="form.data.expense_category.value"
          :disabled="categories.loading"
        >
          Expense Category
        </form-group>

        <form-group
          type="file"
          left-icon="receipt-outline"
          name="receipt"
          :form="form"
          v-model="form.data.receipt.value"
          :autofocus="true"
        >
          Upload Receipt (Optional)
        </form-group>

        <div class="w-5/10 mx-auto">
          <button
            type="submit"
            class="btn btn-blue btn-md mb-4 w-full"
            :disabled="form.loading"
          >
            <template v-if="form.loading">Submitting...</template>
            <template v-else>
              Submit
            </template>
          </button>

        </div>

      </form>

    </modal>

    <modal className="w-full md:w-2/5 xl:w-3/10 text-center pt-24" ref="successModal">
      <img :src="assets.icons.lg.checkmark" class="mx-auto mb-8" height="90" alt="Check Mark">

      <div class="text-lg font-bold mb-4">
        Success!
      </div>
      <div class="text-sm mb-10">
        Expense Details Updated.
      </div>

      <button type="button" class="btn btn-blue" @click.prevent="$refs.successModal.close">
        Okay
      </button>
    </modal>
    
  </div>
</template>

<script>
  export default {
    data() {
      return {
        card: null,
        transaction: null,
        categories: this.$options.resource([]),
        form: this.$options.basicForm([
          'description',
          'expense_category',
          { name: 'usage', value: 'corporate' },
          { name: 'receipt', value: null, rules: 'nullable' },
        ]),
      }
    },
    computed: {
      subCategories() {
        var subCategories = [];

        this.categories.data?.forEach( category => {
          subCategories = [
            ...subCategories,
            ...category.company_expense_subcategory.map( subCategory => ({ title: subCategory.label, value: subCategory.id }))
          ]
        } );

        return subCategories;
      }
    },
    beforeMount() {
      this.getCategories();
    },
    methods: {
      close() {
        this.$refs.modal.close();
      },
      open(card, transaction) {
        this.card = card;
        this.transaction = transaction;
        this.$refs.modal.open();
      },

      async getCategories() {
        this.categories.loading = true;

        await this.sendRequest('personal.expenseCategories.all', this.user.company_id, {
          success: response => {
            this.categories.data = response.data.data;
          },
          error: error => {
            this.categories.error = error;
          }
        });

        this.categories.loading = false;
      },
      async submit() {
        if (!this.validateForm(this.form)) {
          return false;
        }

        const data = new FormData();

        data.append('transaction_id', this.transaction.id);
        data.append('expense_sub_category_id', this.form.data.expense_category.value);
        data.append('usage',  this.form.data.usage.value);
        data.append('receipt', 'nullable');
        data.append('upload_status', this.form.data.receipt.value ? 'completed' : 'pending');
        data.append('description', this.form.data.description.value);

        this.form.loading = true;
        this.form.error = false;

        await this.sendRequest('personal.expenses.report', {
          data,
          success: response => {
            this.$emit('success', response)
            this.close();
            this.$refs.successModal.open();
          },
          error: error => {
            this.form.error = error;
            this.mapFormErrors(this.form, error.response?.data?.errors)
          }
        });

        this.form.loading = false;
      }
    }
  }
</script>